.formWrapper{
    display :flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content: center;
}
.formBox{
    border: 2px solid black;
    margin: 5px;
    padding: 5px;
}
.formBox h5{
    margin: 3px;
    text-decoration: underline;
}